<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          :disabled="moderator"
          color="error"
          small
          v-bind="attrs"
          v-on="on">
      >
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title>Fall löschen</v-card-title>
      <v-card-text>
        Möchten Sie den Fall wirklich löschen?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            small
            @click="deleteItem(item)"
        >
          Fall endgültig löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import reactiveStorage from "@/plugins/reactiveStorage";

export default {
  name: "ExportDeleteItem",
  props: {
    item: { required: true },
    url: { required: true },
  },
  data: () => ({
    dialog: false,
    moderator: reactiveStorage.user.roles.Moderator,
  }),
  methods: {
    async deleteItem(item) {
      //  if Session isValid
      await sessionHandler();
      this.isLoadingTable = true
      this.$http
          .delete(
              this.url + "/" + item.id
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then(() => {
            this.dialog = false
            this.$emit('updateTable')
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAB2", "error");
            }
          })
          .finally(() => {
            this.isLoadingTable = false;
          })
    },
  }
}
</script>

<style scoped>

</style>
